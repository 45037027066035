import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Personnel from "./components/Personnel";
import PersonnelImage from "./components/PersonnelImage";
import ServiceAgreement from "./components/ServiceAgreement";
import Services from "./components/Services";



function App() {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <Services />
      <ServiceAgreement />
      <About />
      <Contact />
      <PersonnelImage />
      <Personnel />
      <Footer />
    </div>
  );
}

export default App;
