import React from 'react'
import Background from '../assets/hero.jpg'
import { AiFillStar } from 'react-icons/ai'
import { Link } from 'react-scroll'

const Home = () => {
  return (
    <div name="home" className='w-full h-screen'>
        <img src={Background} alt="Fläktmontering av NPI service" className="absolute object-cover h-[90%] w-full" />

        <div className='absolute max-w-[1000px] lg:mx-24 px-8 flex flex-col justify-center h-full'>
            <h1 className='text-5xl sm:text-8xl font-bold text-white'>Välkommen till NPI Service</h1>
            <p className='sm:text-4xl text-2xl py-4 max-w-[700px] text-white'>Experter inom ventilation i Kristinehamn</p>
            <div>
              <Link to="contact" smooth={true} duration={500}>
                <button className='text-white text-lg px-14 py-4 rounded-full flex items-center bg-[#FB8500]'>Kontakta oss</button>
                </Link>
            </div>
        </div>

        <div className='h-[10%] w-full absolute bottom-0 lg:pl-24 flex items-center pl-8 bg-[#fbf4eb]'>
            <p className='text-[#004A79] text-xl sm:pl-8 pr-2'>5,0</p>
            <AiFillStar className='text-[#FB9119]' />
            <AiFillStar className='text-[#FB9119]' />
            <AiFillStar className='text-[#FB9119]' />
            <AiFillStar className='text-[#FB9119]' />
            <AiFillStar className='text-[#FB9119]' />
            <a className='text-[#FB9119] underline px-4' href='https://www.google.com/search?q=npi+service+kristinehamn&rlz=1C1CHBD_svSE1029SE1029&ei=uXOtY8mkNa-Vxc8Po_W84As&ved=0ahUKEwiJ5dvY1p78AhWvSvEDHaM6D7wQ4dUDCA8&uact=5&oq=npi+service+kristinehamn&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIGCAAQFhAeMgIIJjIFCAAQhgMyBQgAEIYDMgUIABCGAzIFCAAQhgM6CggAEEcQ1gQQsAM6BQgAEIAEOggIABAWEB4QDzoFCCEQoAFKBAhBGABKBAhGGABQigdYnx1g_R1oBHABeACAAYwBiAHTDJIBBDEwLjaYAQCgAQHIAQPAAQE&sclient=gws-wiz-serp' target="_blank">Läs recensionerna på google.se</a>
        </div>
    </div>
  )
}

export default Home