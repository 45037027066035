import React from 'react'
import { Link } from 'react-scroll'
import Location from '../assets/googlemaps.png'

const Contact = () => {
  return (
    <div name="contact" className='w-full h-full my-16'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full grid'>
                <div className='pb-8 px-4 sm:pl-4'>
                    <p className='text-sm font-bold inline text-[#FB9119]'>
                        Kontakt
                    </p>
                    <div>
                        <div></div>
                </div>
                <div className='max-w-[1000px] relative w-full'>
                   <a href='https://www.google.com/maps/place/2:a+Industrigatan+8,+681+30+Kristinehamn/data=!4m2!3m1!1s0x465c912b9a834185:0xa23a876736e9c759?sa=X&ved=2ahUKEwin1bPY99P8AhWns4sKHajRCC4Q8gF6BAgLEAI' target="_blank"><img src={Location} className="pt-4" /> </a> 
                <div className='sm:absolute bottom-[-75px] md:-right-0 lg:-right-[20px] bg-white py-12 px-12 shadow-lg rounded-xl overflow-hidden'>
                    <p className='py-4 font-bold text-[#FB9119]'>Adress: <a className='text-black font-normal' href='https://www.google.com/maps/place/2:a+Industrigatan+8,+681+30+Kristinehamn/data=!4m2!3m1!1s0x465c912b9a834185:0xa23a876736e9c759?sa=X&ved=2ahUKEwin1bPY99P8AhWns4sKHajRCC4Q8gF6BAgLEAI' target="_blank">2:a Industrigatan 8, 681 30 Kristinehamn</a></p>
                    
                    <p className='font-bold text-[#FB9119]'>Telefon: <a className='text-black font-normal' href="tel:055013660">0550-136 60</a></p>
                    
                </div>
                </div>
            </div>
            
            <div className='pb-8 pl-4 sm:w-1/2 flex flex-col'>
                <div className='px-4 py-4'>
                
                
                <p className='text-[#195C86] font-bold text-2xl pb-4'>Kontakta oss idag för hjälp med service & installation
</p>
                <p>Funderar du över något? Tveka inte att kontakta oss via telefon, eller <Link to="personnel" className='underline cursor-pointer' smooth={true} duration={500}>e-mail</Link>. Vi gör vårt yttersta för att svara på alla dina frågor. 



                </p>
            </div>
            </div>
            
        </div>
    </div>
    </div>
  )
}

export default Contact