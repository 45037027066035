import React from 'react'
import Peter from '../assets/PeterNPI.jpg'
import Jonas from '../assets/DSC_6927.png'
import Jorgen from '../assets/DSC_6923.png'
import Josef from '../assets/DSC_6934.png'
import Sofia from '../assets/sofia.jpg'

const Personnel = () => {
  return (
    <div name="personnel" className='w-full h-full my-24'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full items-center justify-center grid sm:grid-cols-2 sm:gap-8'>
                <div className='sm:pb-8 px-4 flex flex-col sm:gap-20'>
                    <p className='text-sm font-bold inline text-[#FB9119]'>
                        Personal
                <h4 className='text-[#195C86] font-bold text-2xl pb-4'>Vi som jobbar på NPI Service</h4>
                    </p>
                <div className='max-w-[1000px] mb-12 md:mb-0 relative sm:w-[80%]'>
                  <img src={Peter} alt="NPI service personal" className="pt-4" />
                <div className='sm:absolute bottom-[-75px] right-[-100px] bg-white py-6 px-12 shadow-lg rounded-xl'>
                    <p className='font-bold text-[#FB9119]'>Peter Eliasson</p>
                    <a className='text-black font-normal' href='tel:070-293 22 53'>Mobil: 070-293 22 53</a>
                    <br />
                    <a href='mailto:peter@npiservice.se'>E-post: peter@npiservice.se</a>
                </div>
                </div>
                <div className='max-w-[1000px] mb-12 md:mb-0 relative sm:w-[80%]'>
                  <img src={Jonas} alt="NPI service personal" className="pt-4" />
                <div className='sm:absolute bottom-[-75px] right-[-100px] bg-white py-6 px-12 shadow-lg rounded-xl'>
                    <p className='font-bold text-[#FB9119]'>Jonas Forssten</p>
                    <a className='text-black font-normal' href='tel:073-046 36 12'>Mobil: 073-046 36 12</a>
                    <br />
                    <a href='mailto:jonas@npiservice.se'>E-post: jonas@npiservice.se</a>
                </div>
                </div>
                <div className='max-w-[1000px] relative sm:w-[80%]'>
                  <img src={Sofia} alt="NPI service personal" className="w-full object-cover h-[255px] pt-4" />
                <div className='sm:absolute bottom-[-75px] right-[-100px] bg-white py-6 px-12 shadow-lg rounded-xl'>
                    <p className='font-bold text-[#FB9119]'>Sofia Södervall</p>
                    <a className='text-black font-normal' href='tel:070-363 66 36'>Mobil: 070-363 66 36</a>
                    <br />
                    <a href='mailto:sofia@npiservice.se'>E-post: sofia@npiservice.se</a>
                </div>
                </div>
            </div>
            
            <div className='sm:pb-8 px-4 flex flex-col sm:gap-20'>
              <p className='text-sm font-bold invisible text-[#FB9119]'>
                        Personal
                <h4 className='text-[#195C86] font-bold text-2xl pb-4'>Vi som jobbar på NPI Service</h4>
                    </p>
               <div className='max-w-[1000px] mb-12 md:mb-0 relative sm:w-[80%]'>
                  <img src={Jorgen} alt="NPI service personal" className="pt-4" />
                <div className='sm:absolute bottom-[-75px] md:-right-[80px] lg:right-[-100px] bg-white py-6 px-12 shadow-lg rounded-xl'>
                    <p className='font-bold text-[#FB9119]'>Jörgen Jensen</p>
                    <a className='text-black font-normal' href='tel:070-293 22 23'>Mobil: 070-293 22 23</a>
                    <br />
                    <a href='mailto:jorgen@npiservice.se'>E-post: jorgen@npiservice.se</a>
                </div>
                </div>
                <div className='max-w-[1000px] relative sm:w-[80%]'>
                  <img src={Josef} alt="NPI service personal" className="pt-4" />
                <div className='sm:absolute bottom-[-75px] md:-right-[80px] lg:right-[-100px] bg-white py-6 px-12 shadow-lg rounded-xl'>
                    <p className='font-bold text-[#FB9119]'>Josef Germer</p>
                    <a className='text-black font-normal' href='tel:073-338 49 39'>Mobil: 073-338 49 39</a>
                    <br />
                    <a href='mailto:josef@npiservice.se'>E-post: josef@npiservice.se</a>
                </div>
                </div>
                <div className='hidden sm:block sm:invisible max-w-[1000px] relative sm:w-[80%]'>
                  <img src={Josef} alt="NPI service personal" className="pt-4" />
                <div className='sm:absolute bottom-[-75px] md:-right-[80px] lg:right-[-100px] bg-white py-6 px-12 shadow-lg rounded-xl'>
                    <p className='font-bold text-[#FB9119]'>Josef Germer</p>
                    <a className='text-black font-normal' href='tel:073-338 49 39'>Mobil: 073-338 49 39</a>
                    <br />
                    <a href='mailto:peter@npiservice.se'>E-post: josef@npiservice.se</a>
                </div>
                </div>
            </div>
            
        </div>
    </div>
    </div>
  )
}

export default Personnel