import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import Logo from '../assets/NPI_logotyp.png'
import { Link } from 'react-scroll'

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)

  return (
    <div className='absolute z-10 w-full h-[80px] flex justify-between items-center px-8 pt-8 text-white bg-transparent'>
        <div>
            <img src={Logo} alt="Logo image" style={{ width: '80px' }} />
        </div>

        
            <ul className='hidden md:flex'>
                <li>
                    <Link to="services" smooth={true} duration={500}>
                    Våra tjänster
                    </Link>
                </li>
                <li>
                    <Link to="about" smooth={true} duration={500}>
                    Om oss
                    </Link>
                </li>
                <li>
                    <Link to="contact" smooth={true} duration={500}>
                    Kontakt
                    </Link>
                </li>
            </ul>
        
        {/* Hamburger */}
        <div onClick={handleClick} className='md:hidden z-10'>
            {!nav ? <FaBars /> : <FaTimes />} 
        </div>

        {/* Mobile menu */}
            <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#195C86] flex flex-col justify-center items-center'}>
                <li className='py-6 text-4xl'><Link onClick={handleClick} to="services" smooth={true} duration={500}>
                    Våra tjänster
                    </Link></li>
                <li className='py-6 text-4xl'><Link onClick={handleClick} to="about" smooth={true} duration={500}>
                    Om oss
                    </Link></li>
                <li className='py-6 text-4xl'><Link onClick={handleClick} to="contact" smooth={true} duration={500}>
                    Kontakt
                    </Link></li>
            </ul>
    </div>
  )
}

export default Navbar