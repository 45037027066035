import React from 'react'
import ServiceImg from '../assets/DSC_7001.jpg'
import { BsFillPenFill } from 'react-icons/bs'

const ServiceAgreement = () => {
  return (
    <div className='w-full h-[80vh] sm:h-[60vh] flex justify-center text-center items-center'>
        <div className='bg-img w-full h-full'>
            <div className='blue-filter w-full h-full'></div>
        </div>
        <div className='absolute text-white px-4 py-8 flex flex-col max-w-[1000px] text items-center justify-center '>
          <div className="flex sm:flex-row flex-col items-center justify-center">
          <BsFillPenFill className='w-12 h-12 sm:mx-4' />
            <h2 className='text-4xl py-4 font-bold'>SERVICEAVTAL</h2>
            </div>
            <p>Vi  erbjuder serviceavtal som kan skräddarsys enligt era krav och behov. Målsättningen med våra serviceavtal är bekymmersfri drift, långsiktig hållbarhet samt god driftekonomi för dig som kund. Vi kommer då enligt avtalet att titta till er anläggning minst 1-2 gånger per år beroende på behov.
Idag har vi ca 20-25 serviceavtal med företag och nöjda kunder runt om i Värmland.
Vi välkomnar självklart fler! 
</p>
            <p className='py-4'>Hör av dig till oss på NPI Service.</p>
            <a href="NPI_Service.pdf" download="NPI_Serviceavtal">
            <button className='text-white whitespace-nowrap px-14 py-4 rounded-full flex items-center bg-[#FB8500]'>Läs mer om vårt serviceavtal</button>
            </a>
        </div>
    </div>
  )
}

export default ServiceAgreement