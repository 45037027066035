import React from 'react'

const PersonnelImage = () => {
  return (
    <div className='w-full h-[60vh] flex justify-center text-center items-center'>
        <div className='personnel-img w-full h-full'>
            <div className='blue-filter w-full h-full'></div>
        </div>
        <div className='absolute text-white py-8 flex flex-col max-w-[1000px] text items-center justify-center '>
        </div>
    </div>
  )
}

export default PersonnelImage