import React from 'react'
import { AiFillFacebook } from 'react-icons/ai'
import Logo from '../assets/NPI_logotyp.png'

const Footer = () => {
  return (
    <div className='absolute z-10 w-full sm:h-[25%] flex flex-col py-8 items-start sm:grid sm:grid-cols-3 justify-between sm:items-center px-4 text-white bg-[#195C86]'>
        <div className='sm:pl-16'>
            <img src={Logo} alt="Logo image" style={{ width: '120px' }} />
        </div>

        
        <div>
          <p className='font-bold pt-4'>Adress:</p>
          <a href='https://www.google.com/maps/place/2:a+Industrigatan+8,+681+30+Kristinehamn/data=!4m2!3m1!1s0x465c912b9a834185:0xa23a876736e9c759?sa=X&ved=2ahUKEwin1bPY99P8AhWns4sKHajRCC4Q8gF6BAgLEAI' target="_blank">2:a Industrigatan 8, <br />
           861 30 Kristinehamn</a>
           
           <p className='sm:pt-6'>Org nr. 556666-3570</p>
        </div>

        <div className='flex flex-col'>
          <p className='font-bold pt-4'>Telefon:</p>
          <a href='tel:055013660'>0550-136 60</a>
          <a href='https://facebook.com/npiservice' target="_blank">
          <p className='pt-6 flex items-center'><AiFillFacebook className='inline h-[25px] w-[25px] mr-2' />Besök oss på Facebook!</p>
          </a>
        </div>
        
        

        
    </div>
  )
}

export default Footer