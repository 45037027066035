import React from 'react'
import AboutImg from '../assets/Gruppbild.jpg'

const About = () => {
  return (
    <div name="about" className='w-full h-full my-16'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full grid sm:grid-cols-2  gap-8'>
                <div className='pb-8 px-4 sm:pl-4'>
                    <p className='text-sm font-bold inline text-[#FB9119]'>
                        Om oss
                    </p>
                    <div>
                        <div></div>
                </div>
                <div className='max-w-[1000px] w-full grid gap-4 text-start'>
                    <img src={AboutImg} className="pt-4" /> 
                </div>
            </div>
            
            <div className='pb-8 pl-4 flex flex-col'>
                <div className='px-4 py-4 sm:pt-5'>
                
                
                <p className='text-[#195C86] font-bold text-2xl pb-4'>Om NPI Service</p>
                <p>NPI Service grundades i Kristinehamn 2004. Med som grundare var Jörgen Jensen och Peter Eliasson, som idag driver och äger företaget. Tillsammans har de över 65 års erfarenhet i ventilationsbranschen.<br />Genom åren har NPI utvecklat ett brett kontaktnät och arbetar med alla typer av service och underhåll av ventilationssystem.
                  Idag har vi även utökat vår verksamhet med Jonas och Josef.<br /> Våra kunder är bland annat stora och små företag, bostadsrättsföreningar, fastighetsbolag och privatpersoner. Vår styrka är långsiktiga kundrelationer med hållbara serviceavtal. 
                </p>
            </div>
            </div>
            
        </div>
    </div>
    </div>
  )
}

export default About