import React from 'react'
import { AiOutlineCheckCircle } from 'react-icons/ai'

const Services = () => {
  return (
    <div name="services" className='w-full h-full mt-4 sm:mt-12 mb-8'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full grid sm:grid-cols-2'>
                <div className='pb-8 px-4'>
                    {/* <p className='text-sm font-bold inline text-[#FB9119]'>
                        Tjänster
                    </p> */}
                    <div>
                        <div></div>
                </div>
                <div className='max-w-[1000px] w-full grid gap-4 text-start'>
                    <h4 className='text-2xl text-[#004A79] font-bold'>Ta hand om ditt inomhusklimat!</h4>
                    <p>
                        Brist på skötsel och underhåll av ert ventilationssystem leder med tiden till en försämrad funktion i anläggningen. Detta kan bero på t.ex. täta filter, smuts i kanaler eller fläktar som behöver service.
                        Brister som dessa kan bidra till sämre inomhusmiljö, driftskostnader och livslängd på utrustning. Genom en kontroll lokaliserar vi vilka problem som finns i anläggningen och hjälper er med åtgärder för att få ert system att prestera så bra som möjligt.
                        Tecknar ni ett serviceavtal med oss sker denna kontroll av systemet löpande med överenskommet intervall. </p>
                    <h4 className='text-2xl mt-8 text-[#004A79] font-bold'>
                        Ett energieffektivt alternativ
                    </h4>
                    <p>Vi bistår också med utbyten av ventilationsaggregat till dagens mer energieffektiva alternativ likväl som installationer av kompletta ventilationssystem i befintliga eller nybyggda byggnader.
                    </p>
                </div>
            </div>
            <div className='flex items-center'>
            <div className='pb-8 sm:px-6 flex px-4 flex-col rounded-xl sm:shadow-service'>
                <div className='sm:px-4 py-4'>
                <h2 className='text-[#004A79] font-bold text-2xl pt-8'>Våra tjänster</h2>
                <ul className='py-4 font-bold  text-[#4A4A4A]'>
                    <li className='flex py-2 cursor-default items-center'><AiOutlineCheckCircle className='text-[#FB9119] mr-2' />Service av ventilation</li>
                    <li className='flex py-2 cursor-default items-center'><AiOutlineCheckCircle className='text-[#FB9119] mr-2' />Luftmätningar/injusteringar</li>
                    <li className='flex py-2 cursor-default items-center'><AiOutlineCheckCircle className='text-[#FB9119] mr-2' />Funktionskontroll</li>
                    <li className='flex py-2 cursor-default items-center'><AiOutlineCheckCircle className='text-[#FB9119] mr-2' />Styr & reglerteknik</li>
                    <li className='flex py-2 cursor-default items-center'><AiOutlineCheckCircle className='text-[#FB9119] mr-2' />Felsökning</li>
                    <li className='flex py-2 cursor-default items-center'><AiOutlineCheckCircle className='text-[#FB9119] mr-2' />Byte av fläktar, spiskåpor, filter m.m.</li>
                </ul>
                <p className='text-[#FB8500] font-bold text-lg'>Vi säljer & installerar bl.a. dessa produkter:</p>
                <p className='px-1'>Spiskåpor, spisfläktar, takfläktar, kanalfläktar filter spiro och spirodetaljer reservdelar till ventilationsprodukter. Vi säljer och installerar våra produkter. </p>
            </div>
            </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Services